// Copyright (C) 2019-2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

const BASE_STROKE_WIDTH = 1.25;
const BASE_GRID_WIDTH = 2;
const BASE_POINT_SIZE = 5;
const TEXT_MARGIN = 10;
const AREA_THRESHOLD = 9;
const SIZE_THRESHOLD = 3;
const POINTS_STROKE_WIDTH = 1;
const POINTS_SELECTED_STROKE_WIDTH = 4;
const MIN_EDGE_LENGTH = 3;
const CUBOID_ACTIVE_EDGE_STROKE_WIDTH = 2.5;
const CUBOID_UNACTIVE_EDGE_STROKE_WIDTH = 1.75;
const UNDEFINED_ATTRIBUTE_VALUE = '__undefined__';
const ARROW_PATH = 'M13.162 6.284L.682.524a.483.483 0 0 0-.574.134.477.477 0 '
    + '0 0-.012.59L4.2 6.72.096 12.192a.479.479 0 0 0 .585.724l12.48-5.76a.48.48 0 0 0 0-.872z';
const BASE_PATTERN_SIZE = 5;

export default {
    BASE_STROKE_WIDTH,
    BASE_GRID_WIDTH,
    BASE_POINT_SIZE,
    TEXT_MARGIN,
    AREA_THRESHOLD,
    SIZE_THRESHOLD,
    POINTS_STROKE_WIDTH,
    POINTS_SELECTED_STROKE_WIDTH,
    MIN_EDGE_LENGTH,
    CUBOID_ACTIVE_EDGE_STROKE_WIDTH,
    CUBOID_UNACTIVE_EDGE_STROKE_WIDTH,
    UNDEFINED_ATTRIBUTE_VALUE,
    ARROW_PATH,
    BASE_PATTERN_SIZE,
};
